class TextScrambler {
    constructor(el) {
        this.el = el
        this.chars = '!<>-_\\/[]{}—=+*^?#________'
        this.update = this.update.bind(this)
    }

    setText(newText) {
        const oldText = this.el.innerText
        const length = Math.max(oldText.length, newText.length)
        const promise = new Promise((resolve) => this.resolve = resolve)
        this.queue = []
        for (let i = 0; i < length; i++) {
            const from = oldText[i] || ''
            const to = newText[i] || ''
            const start = Math.floor(Math.random() * 40)
            const end = start + Math.floor(Math.random() * 40)
            this.queue.push({ from, to, start, end })
        }
        cancelAnimationFrame(this.frameRequest)
        this.frame = 0
        this.update()
        return promise
    }

    update() {
      let output = ''
      let complete = 0
      for (let i = 0, n = this.queue.length; i < n; i++) {
        let { from, to, start, end, char } = this.queue[i]
        if (this.frame >= end) {
            complete++
            output += to
        } else if (this.frame >= start) {
            if (!char || Math.random() < 0.28) {
                char = this.randomChar()
                this.queue[i].char = char
            }
            output += `<span class="dud">${char}</span>`
        } else {
            output += from
        }
      }
      this.el.innerHTML = output
      if (complete === this.queue.length) {
            this.resolve()
      } else {
            this.frameRequest = requestAnimationFrame(this.update)
            this.frame++
      }
    }

    randomChar() {
        return this.chars[Math.floor(Math.random() * this.chars.length)]
    }
}

const throttle = (func, limit) => {
    let lastFunc;
    let lastRan;
    return function() {
        const context = this;
        const args = arguments;
        if (!lastRan) {
            func.apply(context, args);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function() {
                if ((Date.now() - lastRan) >= limit) {
                    func.apply(context, args);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    }
}

class Nav {
    constructor() {
        const navHeight = document.querySelector(".nav").getBoundingClientRect().height;
        const mainNavLinks = document.querySelectorAll(".nav .sections a");

        window.addEventListener("scroll", throttle(() => {

                const fromTop = window.scrollY;

                let prevSectionEnd = document.querySelector(mainNavLinks[0].hash).offsetTop - navHeight
                for (let i = 0; i < mainNavLinks.length; i++) {
                    const link = mainNavLinks[i];
                    
                    const section = document.querySelector(link.hash);
                    const sectionEnd = section.offsetTop - navHeight + section.offsetHeight

                    if (
                        prevSectionEnd <= fromTop &&
                        sectionEnd > fromTop
                    ) {
                        link.classList.add("active");
                    } else {
                        link.classList.remove("active");
                    }

                    prevSectionEnd = sectionEnd
                }
            }, 100));
    }
}

(function() {
    const phrases = [
        "I'm a Software Engineer.",
        "I love solving problems ",
        "and learning new things!",
    ]

    const el = document.querySelector('.text')
    const fx = new TextScrambler(el)

    let counter = 0
    const next = () => {
        fx.setText(phrases[counter]).then(() => {
            setTimeout(next, 800)
        })
        counter = (counter + 1) % phrases.length
    }
    
    next()

    new Nav()
})();

class ContactForm {
    constructor(contactForm) {
        this.contactForm = contactForm

        // Figure out letter width
        const span = document.createElement('span')
        span.innerText = 'a'
        contactForm.appendChild(span)

        this.charWidth = span.getBoundingClientRect().width
        span.remove()

        // Attach listeners to lineviews
        const lineviews = Array.from(contactForm.querySelectorAll('.input-lineview-content'))
        for (const lineview of lineviews) {
            this.addLineViewListeners(lineview)
        }
    }

    createLineView() {
        const lineview = document.createElement('div')
        lineview.classList.add('input-lineview')

        const lineviewContent = document.createElement('div')
        lineviewContent.classList.add('input-lineview-content')
        lineviewContent.classList.add('empty')
        lineviewContent.contentEditable = true

        this.addLineViewListeners(lineviewContent)

        lineview.appendChild(lineviewContent)

        return lineview
    }

    activateLineView(lineview, cursorPos) {
        if (cursorPos > lineview.innerText.length) {
            cursorPos = lineview.innerText.length
        } else if (cursorPos < 0) {
            cursorPos = 0
        }

        const offset = this.charWidth * (lineview.innerText.length - cursorPos)
        
        if (lineview.innerText.length > 0) {
            const range = document.createRange();
            const sel = window.getSelection();
            range.setStart(lineview.childNodes[0], cursorPos);
            range.collapse(true);
            sel.removeAllRanges();
            sel.addRange(range);
        }

        lineview.focus()
        lineview.parentNode.classList.add('active')

        lineview.parentNode.style.setProperty('--cursor-offset', offset + "px")
    }

    addLineViewListeners(lineview) {
        lineview.addEventListener('keydown', e => {this.handleLineViewKeyDown(e)})
        lineview.addEventListener('click', e => {this.handleLineViewClick(e)})
        lineview.addEventListener('focusin', e => {this.handleLineViewFocusIn(e)})
        lineview.addEventListener('focusout', e => {this.handleLineViewFocusOut(e)})
    }

    handleLineViewKeyDown(e) {
        const currentCursorOffset = parseFloat(e.target.parentNode.style.getPropertyValue('--cursor-offset')) || 0
        let newCursorOffset, stepDiv, cursorPos

        if (e.target.innerText.length == 0) {
            e.target.classList.add('empty')
        } else {
            e.target.classList.remove('empty')
        }

        switch (e.key) {
            case "ArrowLeft":
                const inputWidth = e.target.getBoundingClientRect().width
                newCursorOffset = Math.min(inputWidth, currentCursorOffset + this.charWidth)
                e.target.parentNode.style.setProperty('--cursor-offset', newCursorOffset + 'px')
                break

            case "ArrowRight":
                newCursorOffset = Math.max(0, currentCursorOffset - this.charWidth)
                e.target.parentNode.style.setProperty('--cursor-offset', newCursorOffset + 'px')
                break

            case "ArrowUp":
                e.preventDefault()
                let prevLineviewContent

                if (e.target.parentNode.previousSibling && e.target.parentNode.previousSibling.classList.contains('input-lineview')) {
                    prevLineviewContent = e.target.parentNode.previousSibling.querySelector('.input-lineview-content')
                    cursorPos = e.target.innerText.length - Math.round(currentCursorOffset / this.charWidth)
                } else {
                    stepDiv = e.target.closest(".step")
                    const previousStepDiv = stepDiv.previousSibling
                    const prevStepLineviews = Array.from(previousStepDiv.querySelectorAll('.input-lineview-content'))
                    prevLineviewContent = prevStepLineviews[prevStepLineviews.length - 1]
                    cursorPos = prevLineviewContent.innerText.length
                }

                this.activateLineView(prevLineviewContent, cursorPos)
                break

            case "ArrowDown":
                e.preventDefault()
                let nextLineviewContent

                if (e.target.parentNode.nextSibling && e.target.parentNode.nextSibling.classList.contains('input-lineview')) {
                    nextLineviewContent = e.target.parentNode.nextSibling.querySelector('.input-lineview-content')
                    cursorPos = e.target.innerText.length - Math.round(currentCursorOffset / this.charWidth)
                } else {
                    stepDiv = e.target.closest(".step")
                    const nextStepDiv = stepDiv.nextSibling
                    nextLineviewContent = nextStepDiv.querySelector('.input-lineview-content')
                    cursorPos = nextLineviewContent.innerText.length
                }

                this.activateLineView(nextLineviewContent, cursorPos)
                break
            
            case "Backspace":
                if (
                    e.target.innerText.length == 0 && 
                    e.target.previousSibling && 
                    e.target.previousSibling.classList.contains('input-lineview')
                ) {
                    this.activateLineView(e.target.previousSibling)
                    e.target.remove()
                }

                break

            case "Delete":
                // TODO: Handle
                break

            case "Insert":
                // TODO: Handle
                break

            case "Enter":
                e.preventDefault()

                stepDiv = e.target.closest(".step")
                if (stepDiv.classList.contains('message-step')) {
                    // Add new line to message textarea
                    const lineview = this.createLineView()

                    e.target.parentNode.after(lineview)
                    const lineviewContent = lineview.querySelector(`.input-lineview-content`)
                    this.activateLineView(lineviewContent)
                } else {
                    // Go to next step
                    const nextStep = stepDiv.nextSibling
                    nextStep.style.display = 'block'

                    const lineviewContent = nextStep.querySelector(`.input-lineview-content`)
                    this.activateLineView(lineviewContent)
                }

                break
        
            default:
                break
        }
    }

    handleLineViewClick(e) {
        const offset = e.offsetX

        const newCursorOffset = (e.target.innerText.length - getCaretPosition(e.target)) * this.charWidth
        e.target.parentNode.style.setProperty('--cursor-offset', newCursorOffset + 'px')

        e.target.parentNode.classList.add('active')
    }

    handleLineViewFocusIn(e) {
        if (e.target.innerText.length == 0) {
            e.target.parentNode.classList.add('active')
        }
    }

    handleLineViewFocusOut(e) {
        e.target.parentNode.classList.remove('active')
    }

}

function getCaretPosition(editableDiv) {
    var caretPos = 0,
      sel, range;
    if (window.getSelection) {
      sel = window.getSelection();
      if (sel.rangeCount) {
        range = sel.getRangeAt(0);
        if (range.commonAncestorContainer.parentNode == editableDiv) {
          caretPos = range.endOffset;
        }
      }
    } else if (document.selection && document.selection.createRange) {
      range = document.selection.createRange();
      if (range.parentElement() == editableDiv) {
        var tempEl = document.createElement("span");
        editableDiv.insertBefore(tempEl, editableDiv.firstChild);
        var tempRange = range.duplicate();
        tempRange.moveToElementText(tempEl);
        tempRange.setEndPoint("EndToEnd", range);
        caretPos = tempRange.text.length;
      }
    }
    return caretPos;
}

// Contact form
(function() {
    new ContactForm(document.getElementById('contact-form'))

    let step = 1
    
    // let handlingKeyPresses = false
    // const handleKeyPress = e => {
    //     console.log(e)
    //     const text = document.querySelector(`.step-${step} .input-text`)
    //     text.textContent += e.key
    // }

    // contactForm.addEventListener('click', e => {
    //     // console.log("CLICKED!")
    //     contactForm.querySelector(`.step-${step} .input-lineview`).focus()
    //     // if (!handlingKeyPresses) {
    //     //     document.addEventListener('keypress', handleKeyPress)
    //     //     handlingKeyPresses = true
    //     // }
    // });

    let disabled = false

    // console.log("RUNNING!")

    // contactForm.addEventListener('submit', e => {
    //     e.preventDefault()

    //     if (disabled) {
    //         return
    //     }

    //     disabled = true
    //     contactForm.classList.add('disabled')

    //     contactForm.querySelector('button[type="submit"]').innerText = "Sending..."

    //     fetch('/contact', {
    //         method: 'POST',
    //         body: JSON.stringify({
    //             name: contactForm.querySelector('input[name="name"]').value,
    //             email: contactForm.querySelector('input[name="email"]').value,
    //             message: contactForm.querySelector('textarea[name="message"]').value,
    //         })
    //     })
    //     .then((response) => {
    //         if (response.ok) {
    //             contactForm.querySelector('button[type="submit"]').innerText = "Sent"
    //         } else {
    //             disabled = false
    //             contactForm.classList.remove('disabled')
    //             contactForm.querySelector('button[type="submit"]').innerText = "Failed"
    //             setTimeout(() => {
    //                 contactForm.querySelector('button[type="submit"]').innerText = "Send Message"
    //             }, 1000)
    //         }
    //     })
    // })
})();